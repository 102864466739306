import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, Container } from '@material-ui/core';
import { validateEmail, validatePhoneNo } from "../../Utils/constant";
import { HandleRegister } from '../../Store/Login/index';
import { toastAlertType, toastAlert } from '../../CommonComponents/Toast/Toast';
import localVariable from '../../Utils/LocalVariables.json';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
}));

export default function Register() {
    const classes = useStyles();
    let history = useHistory();
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("");
    const [submit, setSubmit] = useState(false);

    const dispatch = useDispatch();
    const registerData = useSelector(state => state.login.loginData)

    useEffect(() => {
        if (Object.keys(registerData).length > 0 && registerData.user) {
            const loginUserObj = registerData.user
            localStorage.setItem(localVariable.LOGINCODE, JSON.stringify(loginUserObj))
            history.push("/profile");
        }
    }, [registerData, history])

    const handleSubmitClick = () => {
        setSubmit(true)
        if (email !== "" && validateEmail(email) && firstName !== "" && lastName !== "" &&
            password !== "" && phone !== "" && validatePhoneNo(phone)) {
            let params = {
                "email": email,
                "firstName": firstName,
                "lastName": lastName,
                "phoneNumber": phone,
                "password": password
            }
            dispatch(HandleRegister(params, {
                SuccessCallback: res => {
                    if (res.data.status === true) {
                    } else {
                        toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                    }
                },
                FailureCallback: error => alert(JSON.stringify(error))
            }))
        }
    };

    const handlePhoneNumberChange = (e) => {
        let phone = e.target.value
        if (phone !== "" && isNaN(parseInt(phone))) {
            return
        } else {
            phone !== "" ? setPhone(parseInt(phone)) : setPhone(phone)
        }
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}><LockOutlinedIcon /></Avatar>
                <Typography component="h1" variant="h5">Sign up</Typography>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField autoComplete="fname" name="firstName" variant="outlined" required
                                fullWidth id="firstName" label="First Name" autoFocus
                                error={firstName === "" && submit} helperText={firstName === "" && submit ? "Please enter FirstName" : ""}
                                value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField variant="outlined" required fullWidth id="lastName" label="Last Name"
                                name="lastName" autoComplete="lname"
                                error={lastName === "" && submit} helperText={(lastName === "" && submit) ? "Please enter LastName" : ""}
                                value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" required fullWidth id="phonenumber" label="Phone Number"
                                name="phonenumber" autoComplete="phone number"
                                error={((phone === "" && submit) || (submit && !validatePhoneNo(phone)))}
                                helperText={((phone === "" && submit) || (submit && !validatePhoneNo(phone))) ? "Please enter valid Phone Number" : ""}
                                value={phone} onChange={handlePhoneNumberChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" required fullWidth id="email" label="Email Address"
                                name="email" autoComplete="email"
                                error={((email === "" && submit) || (submit && !validateEmail(email)))}
                                helperText={((email === "" && submit) || (submit && !validateEmail(email))) ? "Please enter valid Email" : ""}
                                value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" required fullWidth id="password" label="Password"
                                name="password" autoComplete="password" type="password"
                                error={password === "" && submit} helperText={(password === "" && submit) ? "Please enter password" : ""}
                                value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Grid>
                    </Grid>
                    <Button type="button" fullWidth variant="contained"
                        color="primary" className={classes.submit} onClick={() => handleSubmitClick()}>
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to={{ pathname: "/" }}>{" Already have an account? Sign in"}</Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    );
}