import axios from 'axios';
export let endPointsData, paramsData, headersData, methodData, successCallBackData, failureCallbackData

let BaseUrl = "http://ims.sigmasolve.net:5000/api";

export const RestApiController = (endPoints, params, headers, method, { SuccessCallback, FailureCallback }) => {
    endPointsData = endPoints;
    paramsData = params;
    let header = headers
    headersData = header;
    methodData = method;
    successCallBackData = SuccessCallback;
    failureCallbackData = FailureCallback;
    if (navigator.onLine) {
        return axios({
            method: method,
            url: `${BaseUrl}${endPoints}`,
            data: params,
            headers: headers
        }, { SuccessCallback, FailureCallback })
            .then((response) => {
                SuccessCallback(response)
            })
            .catch((error) => {
                FailureCallback(error.message)
            })
    }
}