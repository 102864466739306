import { combineReducers } from 'redux'
import global from './Global'
import login from './Login'
import profile from './Profile'
import admin from './Admin'

// Combine all reducers
export default combineReducers({
    global,
    login,
    profile,
    admin
})

