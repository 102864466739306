import React, { Component } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
export default class AlertPopup extends Component {

	constructor(props) {
		super(props)

		this.state = {
			open: true
		}
	}

	render() {
		const { open } = this.state;
		const { onOkClick, onCloseClick } = this.props;

		return (
			<>
				<Dialog open={open} TransitionComponent={Transition} keepMounted
					onClose={onCloseClick} style={{ minHeight: '200px', minWidth: '350px' }}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">{this.props.displayMsgOnPopup}</DialogTitle>
					<DialogActions style={{ justifyContent: 'center' }}>
						{onCloseClick && <Button onClick={onCloseClick} color="primary">No</Button>}
						{onOkClick && <Button onClick={onOkClick} color="primary">Yes</Button>}
					</DialogActions>
				</Dialog>
			</>
		)
	}
}


