import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Title from './title';
import { Button, ButtonGroup } from '@material-ui/core';
import ViewPaper from '../Paper/viewPaper'
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function PaperList(props) {
  const { PaperListArr, technology, getPaperSetArr, getPaper } = props
  console.log(PaperListArr)

  const handleTech = (row) => {
    if (row === null) return "-"
    return technology.find(o => o.id === row).techName
  }


  return (
    <React.Fragment>
      <Title>Papers</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Sr No.</StyledTableCell>
            <StyledTableCell>Technology</StyledTableCell>
            <StyledTableCell>Paper Set</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            getPaperSetArr.length > 0 && getPaperSetArr.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{technology.length > 0 && handleTech(row.techId)}</TableCell>
                <TableCell>{row.setName}</TableCell>
                <TableCell>
                  <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <Button color="primary" onClick={() => getPaper(row.id)}>View Paper</Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      {PaperListArr?.queOptArr?.length > 0 && <ViewPaper paperArray={PaperListArr} view={true} handleTech={handleTech} />}
    </React.Fragment>
  );
}