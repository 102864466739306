//Header
const headers = {
  'Content-Type': 'application/json',
}

/**
 * Define endpoint menthod and header for API
*/
export const endPoints = {
  Login: { endpoint: "/login", method: 'POST', header: headers },
  Register: { endpoint: "/register", method: 'POST', header: headers },
  Technology: { endpoint: "/tech", method: 'GET', header: headers },
  UpdateProfile: { endpoint: "/profile/user", method: 'PUT', header: headers },
  ApplyForExam: { endpoint: "/profile/applyforexam", method: 'PUT', header: headers },
  UserList: { endpoint: "/admin/user", method: 'GET', header: headers },
  UpdateUserList: { endpoint: "/admin/user", method: 'PUT', header: headers },
  AddTech: { endpoint: "/admin/tech", method: 'POST', header: headers },
  DeleteTech: { endpoint: "/admin/tech", method: 'DELETE', header: headers },
  CreatePaper: { endpoint: "/paper/create", method: 'POST', header: headers },
  GetPaper: { endpoint: "/paper/get", method: 'POST', header: headers },
  GetPaperSet: { endpoint: "/paper/papersets", method: 'GET', header: headers },
  EmailLink: { endpoint: "/admin/email", method: 'POST', header: headers },
  ExamResult: { endpoint: "/exam/result", method: 'POST', header: headers },
  GetCriteria: { endpoint: "/admin/criteria", method: 'GET', header: headers },
  UpdateCriteria: { endpoint: "/admin/criteria", method: 'POST', header: headers },
  GetStartTime: { endpoint: "/exam/start", method: 'POST', header: headers },
  UpdateStartTime: { endpoint: "/exam/start/update", method: 'POST', header: headers },
  GetAudio: { endpoint: "/audio", method: 'GET', header: headers },
};