import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem } from '@material-ui/core';
import Title from './title';
import { Button, ButtonGroup } from '@material-ui/core';
import { NewReleases, Send } from '@material-ui/icons';
import Audio from './audio'

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

export default function UserList(props) {
  const { userList, technology, getPaperSetArr, updateUserPaperset, handleSndLink } = props

  const handleTech = (row) => {
    if (row === null) return "-"
    return technology.find(o => o.id === row).techName
  }

  const handleStatus = (row) => {
    if (row === 1) return "Pass"
    else if (row === 2) return "Pending"
    else if (row === 3) return "Fail"
  }

  return (
    <React.Fragment>
      <Title >Users</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Phone No.</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Technology</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Paper Set</StyledTableCell>
            <StyledTableCell>Score</StyledTableCell>
            <StyledTableCell>Introduction</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            userList.length > 0 && userList.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{row.linkSent === 0 ? <NewReleases style={{ color: 'green' }} /> : null}</TableCell>
                <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                <TableCell>{row.phoneNumber ? row.phoneNumber : "-"}</TableCell>
                <TableCell className="word-wrap">{row.email ? row.email : "-"}</TableCell>
                <TableCell>{technology.length > 0 && handleTech(row.techId)}</TableCell>
                <TableCell>{handleStatus(row.statusId)}</TableCell>
                <TableCell>
                  <Select value={row.setId ? row.setId : ''} onChange={(e) => updateUserPaperset(e.target.value, index, row.id)} style={{ width: 100 }}>
                    <MenuItem value=""><em>None</em></MenuItem>
                    {
                      getPaperSetArr.length > 0 && getPaperSetArr.map((data) => (
                        row.techId === data.techId
                          ? < MenuItem value={data.id} key={data.id}>{data.setName}</MenuItem>
                          : null
                      ))
                    }
                  </Select >
                  {/* {handlePaperSet(row.techId, row.setId, index)} */}
                </TableCell>
                <TableCell>{row.score ? row.score : "-"}</TableCell>
                <TableCell>
                  <Audio id={row.id} />
                </TableCell>
                <TableCell>
                  <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <Button disabled={row.setId === null} color="primary" onClick={() => handleSndLink(row.id, row.setId)}><Send /></Button>
                  </ButtonGroup>
                  <ButtonGroup disabled={row.setId === null} variant="contained" color="primary" aria-label="contained primary button group">
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </React.Fragment>
  );
}