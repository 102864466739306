export const validateEmail = (email) => {
    const regExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    const isValidEmail = regExp.test(String(email).toLowerCase());
    return isValidEmail
}

export const validatePhoneNo = (phone) => {
    const regExp = /^\d{10}$/;
    const isValidphone = regExp.test(String(phone).toLowerCase());
    return isValidphone
}

