import React, { useState } from 'react'
import { Button, Grid, TextField, ButtonGroup, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Title from './title';
import Paper from '@material-ui/core/Paper';
import { Add, Delete } from '@material-ui/icons';
import AlertPopup from '../../CommonComponents/AlertPopup/AlertPopup';

const useStyles = makeStyles({
    pos: {
        marginBottom: 12,
        padding: 15,
        display: 'flex',
        position: 'relative'
    },
    formcontrol: {
        minWidth: 230
    },
    actionbtn: {
        right: 20,
        top: 20,
        position: 'absolute'
    },
});
export default function Technology(props) {
    const classes = useStyles();
    const { technology, addTech, deleteTech } = props
    const [techId, setTechId] = useState("")
    const [tech, setTech] = useState("")
    return (
        <div>
            <Paper elevation={3} className={classes.pos}>
                <Grid item xs={6} sm={3}>
                    <TextField variant="outlined" required id="lastName" label="Technology"
                        name="lastName" autoComplete="lname" value={tech} onChange={(e) => setTech(e.target.value)} />
                </Grid>
                <ButtonGroup size="large" color="primary" className={classes.actionbtn} aria-label="large outlined primary button group">
                    <Button title="Add question" onClick={() => { addTech(tech); setTech("") }}><Add /></Button>
                </ButtonGroup>
            </Paper>
            <Title >Technology</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Sr no.</TableCell>
                        <TableCell>Technology</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {technology.length > 0 && technology.map((data, i) => (
                        <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{data.techName}</TableCell>
                            <TableCell>
                                <Delete onClick={() => setTechId(data.id)} />
                            </TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
            {
                techId !== "" && <AlertPopup displayMsgOnPopup={`Are you sure?`} onOkClick={() => { deleteTech(techId); setTechId("") }} onCloseClick={() => setTechId("")} />
            }
        </div>
    )
}
