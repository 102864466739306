import { toast, Zoom } from 'react-toastify';

/**
 * Display toast alert
*/

export const toastAlertType = {
	ERROR:"error",
	SUCCESS:"success"
}

export const toastAlert = (message, toastType) => {
	toast(message, {
		transition: Zoom,
		closeButton: false,
		autoClose: 5000,
		position: 'top-left',
		hideProgressBar: true,
		type: toastType
	});
}