import * as types from './actionTypes'

// Initial states
const initialState = {
    technology: [],
    updateProfile: {},
    applyForExam: {}
};

export default function apiCallDashboard(state = initialState, action = {}) {
    switch (action.type) {
        case types.TECHNOLOGY_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.TECHNOLOGY_FETCH_SUCCESS:
            return {
                ...state,
                technology: action.payload,
                fetching: false,
            }
        case types.TECHNOLOGY_FETCH_FAIL:
            return {
                ...state,
                technology: action.payload,
                fetching: false,
            }
        case types.UPDATE_PROFILE_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.UPDATE_PROFILE_FETCH_SUCCESS:
            return {
                ...state,
                updateProfile: action.payload,
                fetching: false,
            }
        case types.UPDATE_PROFILE_FETCH_FAIL:
            return {
                ...state,
                updateProfile: action.payload,
                fetching: false,
            }
        case types.APPLY_FOR_EXAM_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.APPLY_FOR_EXAM_FETCH_SUCCESS:
            return {
                ...state,
                applyForExam: action.payload,
                fetching: false,
            }
        case types.APPLY_FOR_EXAM_FETCH_FAIL:
            return {
                ...state,
                applyForExam: action.payload,
                fetching: false,
            }

        default:
            return state;
    }
}