import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducer'

/**
 * Create store
*/
const configureStore = () => {

  // Added middleware thunk
  const middleware = [thunk];
  return createStore(reducer, applyMiddleware(...middleware))
};

export { configureStore }