import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Drawer, AppBar, Toolbar, List, Typography, Divider, IconButton, Container, Grid, Paper } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { PowerSettingsNew } from '@material-ui/icons';
import { HandleLogout } from '../../Store/Login/index';
import { GetUserList, AddTechnology, DeleteTechnology, CreatePaper, GetPaper, GetPaperSet, UpdateUserList, EmailLink } from '../../Store/Admin/index';
import { GetTechnology } from '../../Store/Profile/index';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toastAlertType, toastAlert } from '../../CommonComponents/Toast/Toast';
import MainListItems from './listItems';
import AdminDashboard from './dashboard';
import UserList from './userList';
import Technology from './technology';
import PaperList from './paperList';
import CreatePapers from './createPaper';
import Criteria from './criteria';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    let history = useHistory();

    const [open, setOpen] = useState(true);
    const [sidebar, setSidebar] = useState("Dashboard");
    const dispatch = useDispatch();
    const userList = useSelector(state => state.admin.userList)
    const getPaperSetArr = useSelector(state => state.admin.getPaperSetList)
    const PaperListArr = useSelector(state => state.admin.getPaperList)
    const technologyArr = useSelector(state => state.profile.technology)

    const logout = () => {
        dispatch((HandleLogout()))
        history.push("/");
    }

    const getTechnologies = () => {
        dispatch(GetTechnology(null, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }

    useEffect(() => {
        //Get technologies
        dispatch(GetTechnology(null, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
        //Get user list
        dispatch(GetUserList(null, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }, [dispatch])

    useEffect(() => {
        if (sidebar === "Papers List" || sidebar === "Candidates") {
            dispatch(GetPaperSet(null, {
                SuccessCallback: res => {
                    if (res.data.status === true) {
                    } else {
                        toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                    }
                },
                FailureCallback: error => alert(JSON.stringify(error))
            }))
        }

    }, [sidebar, dispatch])

    const addTech = (tech) => {
        //Add technologies
        const params = {
            "technology": tech
        }
        dispatch(AddTechnology(params, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                    getTechnologies()
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }
    const deleteTech = (id) => {
        //Add technologies
        const params = {
            "techId": parseInt(id)
        }
        dispatch(DeleteTechnology(params, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                    getTechnologies()
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }

    const savePaper = (data) => {
        if (data.setName === "") {
            toastAlert("Please enter Paper-Set Name", toastAlertType.ERROR)
        } else if (data.tech === "") {
            toastAlert("Please select Technology", toastAlertType.ERROR)
        } else if (data.queOptArr.length === 0) {
            toastAlert("Please add atleast one question", toastAlertType.ERROR)
        } else {
            dispatch(CreatePaper(data, {
                SuccessCallback: res => {
                    if (res.data.status === true) {
                        setSidebar("Papers List")
                    } else {
                        toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                    }
                },
                FailureCallback: error => alert(JSON.stringify(error))
            }))
        }

    }

    const getPaperAPICall = (id) => {
        const params = { setId: id }
        dispatch(GetPaper(params, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }

    const updateUserPaperset = (setID, index, userID) => {
        const params = {
            "userId": userID,
            "statusId": 2,
            "setId": setID
        }
        dispatch(UpdateUserList(params, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                    toastAlert(res.data.data.result.message, toastAlertType.SUCCESS)
                    //Get user list
                    dispatch(GetUserList(null, {
                        SuccessCallback: res => {
                            if (res.data.status === true) {
                            } else {
                                toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                            }
                        },
                        FailureCallback: error => alert(JSON.stringify(error))
                    }))
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }

    const handleSndLink = (uId, setId) => {
        const params = {
            "userId": uId,
            "setId": setId
        }
        dispatch(EmailLink(params, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                    toastAlert(res.data.data.result.message, toastAlertType.SUCCESS)
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(true)}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {sidebar}
                    </Typography>
                    <IconButton color="inherit" onClick={logout}>
                        <PowerSettingsNew />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={() => setOpen(false)}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List><MainListItems setSidebar={setSidebar} sidebar={sidebar} /></List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Dashboard */}
                        {
                            sidebar === "Dashboard" && <Grid item xs={12}>
                                <Paper className={classes.fixedHeightPaper}>
                                    <AdminDashboard setSidebar={setSidebar} />
                                </Paper>
                            </Grid>
                        }
                        {/* Users list */}
                        {
                            sidebar === "Candidates" && <Grid item xs={12}>
                                <Paper className={classes.fixedHeightPaper}>
                                    <UserList handleSndLink={handleSndLink} userList={userList} technology={technologyArr} getPaperSetArr={getPaperSetArr} updateUserPaperset={updateUserPaperset} />
                                </Paper>
                            </Grid>
                        }
                        {/* Technology */}
                        {
                            sidebar === "Technology" && <Grid item xs={12}>
                                <Paper elevation={3} className={classes.fixedHeightPaper}>
                                    <Technology deleteTech={deleteTech} addTech={addTech} technology={technologyArr} />
                                </Paper>
                            </Grid>
                        }
                        {
                            sidebar === "Papers List" && <Grid item xs={12}>
                                <Paper elevation={3} className={classes.fixedHeightPaper}>
                                    <PaperList PaperListArr={PaperListArr} getPaperSetArr={getPaperSetArr} technology={technologyArr} getPaper={getPaperAPICall} />
                                </Paper>
                            </Grid>
                        }
                        {/* Create Paper */}
                        {
                            sidebar === "Create Paper" && <Grid item xs={12}>
                                <Paper elevation={3} className={classes.fixedHeightPaper}>
                                    <CreatePapers technologyArr={technologyArr} savePaper={savePaper} />
                                </Paper>
                            </Grid>
                        }
                        {
                            sidebar === "Criterias" && <Grid item xs={12}>
                                <Paper elevation={3} className={classes.fixedHeightPaper}>
                                    <Criteria />
                                </Paper>
                            </Grid>
                        }
                    </Grid>
                </Container>
            </main>
        </div>
    );
}