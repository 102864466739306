import * as types from './actionTypes';
import { endPoints } from '../../CommonApiController/AppConfig';
import { RestApiController } from '../../CommonApiController/ApiController';
import { apiLoadingStart, apiLoadingStop } from '../Global/actions';


export const HandleLogin = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.LOGIN_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.Login.endpoint}`, params, endPoints.Login.header, endPoints.Login.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.LOGIN_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.LOGIN_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const HandleRegister = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.REGISTER_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.Register.endpoint}`, params, endPoints.Register.header, endPoints.Register.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.REGISTER_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.REGISTER_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const HandleLogout = () => {
    return (dispatch) => {
        localStorage.clear();
        dispatch({ type: types.LOGOUT_FETCH_SUCCESS })
    }
};
