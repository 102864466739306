import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Radio, Grid, RadioGroup, FormControlLabel, TextField, ButtonGroup, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardMedia from '@material-ui/core/CardMedia';
// import { sigmaLogo } from '../../Assets'
import { Add, Cancel } from '@material-ui/icons';

const useStyles = makeStyles({
    pos: {
        marginBottom: 12,
        padding: 15,
        display: 'flex',
        position: 'relative'
    },
    quepos: {
        marginBottom: 12,
        padding: 15
    },
    optpos: {
        marginBottom: 5,
        padding: 5
    },
    formcontrol: {
        minWidth: 230
    },
    actionbtn: {
        right: 20,
        top: 20,
        position: 'absolute'
    },
    media: {
        height: 240,
    },
});

export default function CreatePaper(props) {
    const classes = useStyles();
    const { technologyArr, savePaper } = props

    const [questionCard, setQuestionCard] = useState([])
    const [tech, setTech] = useState("")
    const [paperSet, setPaperSet] = useState("")

    const handleChange = (e, idx) => {
        const shadowquestionCard = JSON.parse(JSON.stringify(questionCard))
        const value = e.target.value
        shadowquestionCard[idx].answer = value;
        setQuestionCard(shadowquestionCard)
    };

    const handleSaveClick = () => {
        let data = {
            setName: paperSet,
            tech: tech,
            queOptArr: questionCard
        }
        savePaper(data)
    }
    const handleAddquestion = () => {
        let questionObj = {
            que: "",
            options: [],
            answer: ""
        }
        setQuestionCard([...questionCard, questionObj])
    }

    const handleQueChange = (e, index, idx, key) => {
        const shadowquestionCard = JSON.parse(JSON.stringify(questionCard))
        const value = e.target.value
        if (key === "que") shadowquestionCard[index].que = value;
        if (key === "opt") shadowquestionCard[index].options[idx] = value;
        setQuestionCard(shadowquestionCard)
    }

    const handleAddOption = (index, idx) => {
        const shadowquestionCard = JSON.parse(JSON.stringify(questionCard))
        shadowquestionCard[index].options.push(`Option ${idx + 1}`)
        setQuestionCard(shadowquestionCard)
    }

    const handleDeleteOption = (index, idx) => {
        const shadowquestionCard = JSON.parse(JSON.stringify(questionCard))
        shadowquestionCard[index].options.splice(idx, 1);
        setQuestionCard(shadowquestionCard)
    }
    return (
        <div>
            <Paper elevation={3} className={classes.pos}>
                <Grid item xs={6} sm={3}>
                    <TextField variant="outlined" required id="lastName" label="Paper-set Name"
                        name="lastName" autoComplete="lname" value={paperSet} onChange={(e) => setPaperSet(e.target.value)} />
                </Grid>
                <FormControl required variant="outlined" className={classes.formcontrol}>
                    <InputLabel id="demo-simple-select-outlined-label">Technologies</InputLabel>
                    <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" required
                        value={tech} onChange={(e) => setTech(e.target.value)} label="Technologies">
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            technologyArr.length > 0 && technologyArr.map((data) => (
                                < MenuItem value={data.id} key={data.id}>{data.techName}</MenuItem>)
                            )
                        }
                    </Select>
                </FormControl>
                <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                    <Button variant="contained" color="primary"
                        onClick={() => handleSaveClick()} style={{ marginLeft: 300, width: 100 }}>Save</Button>
                </ButtonGroup>
                <ButtonGroup size="large" color="primary" className={classes.actionbtn} aria-label="large outlined primary button group">
                    <Button title="Add question" onClick={() => handleAddquestion()}><Add /></Button>
                </ButtonGroup>
            </Paper>
            {questionCard.length > 0
                ? questionCard.map((data, index) => (
                    <Paper elevation={3} className={classes.quepos} key={index} >
                        <div className={classes.pos}>
                            {/* <ButtonGroup size="large" color="primary" className={classes.actionbtn} aria-label="large outlined primary button group">
                                    <Button title="Add Image"> <input type="file" style={{ width: 120 }} /></Button>
                                    <Button title="Add Image"> <Delete /></Button>
                                </ButtonGroup> */}
                            <TextField id="filled-basic" value={data.que} onChange={(e) => handleQueChange(e, index, null, "que")} label="Question" style={{ width: '800px' }} variant="filled" />
                            <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                                <Button variant="contained" color="primary"
                                    onClick={() => handleAddOption(index, data.options.length)} style={{ marginLeft: 300, width: 150 }}>Add Option</Button>
                            </ButtonGroup>
                        </div>
                        {/* {data.img && <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image={sigmaLogo}
                                    title="Contemplative Reptile"
                                />
                            </CardActionArea>
                            <CardActions>
                                <Button size="medium" color="primary" style={{ marginLeft: "auto" }}>
                                    <Delete /> Delete
                                </Button>
                            </CardActions>
                        </Card>} */}

                        <div style={{ margin: 15 }}>
                            <FormControl component="fieldset">
                                <RadioGroup value={data.answer} onChange={(e) => handleChange(e, index)}>
                                    {
                                        data.options.length > 0 && data.options.map((optionData, idx) => (
                                            <div key={idx}>
                                                <FormControlLabel value={optionData} className={classes.optpos} control={<Radio />} 
                                                    label={
                                                        <TextField value={optionData} onChange={(e) => handleQueChange(e, index, idx, `opt`)}
                                                            label={`Option ${idx + 1}`} variant="standard" />
                                                    }
                                                />
                                                <Cancel className="onhover" onClick={() => handleDeleteOption(index, idx)} />
                                            </div>
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Paper>
                ))
                : null
            }
        </div >
    )
}
