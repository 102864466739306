//Techonolgy
export const TECHNOLOGY_FETCH_REQUEST = 'TECHNOLOGY_FETCH_REQUEST';
export const TECHNOLOGY_FETCH_SUCCESS = 'TECHNOLOGY_FETCH_SUCCESS';
export const TECHNOLOGY_FETCH_FAIL = 'TECHNOLOGY_FETCH_FAIL';

//Update profile
export const UPDATE_PROFILE_FETCH_REQUEST = 'UPDATE_PROFILE_FETCH_REQUEST';
export const UPDATE_PROFILE_FETCH_SUCCESS = 'UPDATE_PROFILE_FETCH_SUCCESS';
export const UPDATE_PROFILE_FETCH_FAIL = 'UPDATE_PROFILE_FETCH_FAIL';

//APPLY FOR EXAM
export const APPLY_FOR_EXAM_FETCH_REQUEST = 'APPLY_FOR_EXAM_FETCH_REQUEST';
export const APPLY_FOR_EXAM_FETCH_SUCCESS = 'APPLY_FOR_EXAM_FETCH_SUCCESS';
export const APPLY_FOR_EXAM_FETCH_FAIL = 'APPLY_FOR_EXAM_FETCH_FAIL';