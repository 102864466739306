import React, { useState, useEffect, useRef } from 'react'
import { ReactMic } from 'react-mic';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Radio, RadioGroup, FormControlLabel, FormControl, Button, ButtonGroup } from '@material-ui/core';
import MyTimer from './examTimer'
import { GetPaper, ExamResult, GetCriteria, GetStartTime, UpdateStartTime } from '../../Store/Admin/index';
import { useDispatch, useSelector } from 'react-redux';
import { toastAlertType, toastAlert } from '../../CommonComponents/Toast/Toast';
import localVariable from '../../Utils/LocalVariables.json';
import { useHistory } from "react-router-dom";
import IdleTimer from 'react-idle-timer'

const useStyles = makeStyles((theme) => ({

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    pos: {
        marginBottom: 12,
        padding: 15,
        display: 'flex',
        position: 'relative'
    },
    quepos: {
        marginBottom: 12,
        padding: 15
    },
    optpos: {
        marginBottom: 5,
        padding: 5
    },
    formcontrol: {
        minWidth: 230
    },
    actionbtn: {
        right: 20,
        top: 20,
        position: 'absolute'
    },
    media: {
        height: 240,
    },
}));
export default function ExamPaper() {
    const [record, setrecord] = useState(false)
    const [examOptions, setExamOptions] = useState([])
    const [examTimer, setexamTimer] = useState('')
    const [introTimer, setintroTimer] = useState('')
    const [recordedBlob, setrecordedBlob] = useState('')
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const PaperListArr = useSelector(state => state.admin.getPaperList)
    const Criteria = useSelector(state => state.admin.criteriaList)
    const startTime = useSelector(state => state.admin.getStartTime)
    const userData = JSON.parse(localStorage.getItem(localVariable.USERDATA))

    useEffect(() => {
        if (recordedBlob !== "") {
            examResult()
        }
    }, [recordedBlob])

    useEffect(() => {
        if (Criteria.examTime) {
            if (startTime === 0) {
                const startData = { userId: userData.userId }
                dispatch(UpdateStartTime(startData, {
                    SuccessCallback: res => {
                        if (res.data.status === true) {
                        } else {
                            toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                        }
                    },
                    FailureCallback: error => alert(JSON.stringify(error))
                }))
            }
            const timer = new Date();
            const timeDiff = startTime !== 0 ? parseInt((timer / 1000) - startTime) : 0
            if (timeDiff > Criteria.examTime) { history.push("/examover"); }
            let a = timeDiff > Criteria.examTime ? 0 : Criteria.examTime - timeDiff
            timer.setSeconds(timer.getSeconds() + a);
            setexamTimer(timer)
            const time = new Date();
            time.setSeconds(time.getSeconds() + Criteria.introTime);
            setintroTimer(time)

        }
    }, [Criteria, dispatch, history, startTime, userData.userId])

    const introTimerStop = (running) => {
        if (running && !record) {
            setrecord(true)
        } else {
            setrecord(false)
        }
    }
    useEffect(() => {
        const startData = { userId: userData.userId }
        dispatch(GetStartTime(startData, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
        dispatch(GetCriteria(null, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
        const params = { setId: userData.setId }
        dispatch(GetPaper(params, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))

    }, [dispatch, userData.userId, userData.setId])

    const onStop = (Blob) => {
        setrecordedBlob(Blob)
    }

    const handleOptnChng = (e, index) => {
        const shadowCard = JSON.parse(JSON.stringify(examOptions))
        shadowCard[index] = e
        setExamOptions(shadowCard)
    }

    const examResult = () => {
        let ansArr = [], score = 0;
        PaperListArr.queOptArr.map((data) => {
            let optId = data.answer.optionId
            return ansArr.push(data.options.find(o => o.id === optId).option_text)
        })
        ansArr.map((data, index) => {
            return score = data === examOptions[index] ? score + 1 : score + 0
        })
        score = (score / PaperListArr.queOptArr.length) * 100
        const data = recordedBlob ? new File([recordedBlob.blob], "Audio", { lastModified: new Date().getTime(), type: recordedBlob.blob.type }) : null
        const params = new FormData();
        params.append('audioFile', data);
        params.append('userId', userData.userId)
        params.append('status', score > Criteria.passingMarks ? 1 : 3)
        params.append('score', score)
        dispatch(ExamResult(params, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                    const time = (new Date() / 1000) - Criteria.introTime
                    const startData = { userId: userData.userId, time: time }
                    dispatch(UpdateStartTime(startData, {
                        SuccessCallback: res => {
                            if (res.data.status === true) {
                            } else {
                                toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                            }
                        },
                        FailureCallback: error => alert(JSON.stringify(error))
                    }))
                    history.push("/examover");
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }
    const handleSubmitExam = async () => {
        if (record) {
            setrecord(false)
        } else {
            examResult()
        }
    }

    const handleidle = () => {
        toastAlert('you have been idle over long time period, Hence your exam is submitted', toastAlertType.ERROR)
        setTimeout(() => {
            handleSubmitExam()
        }, 5000);
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            {Criteria.idleTime && <IdleTimer
                timeout={Criteria.idleTime * 1000}
                onIdle={() => handleidle()}
                debounce={250}
            />}
            {examTimer !== '' &&
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className={classes.fixedHeightPaper} elevation={3}>
                                    <div style={{ padding: 10 }}>
                                        <h2>Note</h2>
                                        <p>
                                            {"1) Once recording started it won't stop or restart, so only press once you are ready"}<br />
                                            {"2) Don't refresh the page"}<br />
                                        </p>
                                    </div>
                                    <div>
                                        {examTimer !== '' ? <MyTimer expiryTimestamp={examTimer} running={handleSubmitExam} autostart={true} record={null} /> : null}
                                    </div>
                                </Paper>
                                <Paper className={classes.fixedHeightPaper} elevation={3}>
                                    <div style={{ display: 'none' }}>
                                        <ReactMic
                                            record={record}
                                            onStop={(data) => onStop(data)} />
                                    </div>
                                    <div className="recording">
                                        <div className="recording-header">
                                            <h2>Introduce Yourself</h2>
                                        </div>
                                        <div className="duration">
                                            {introTimer !== '' ? <MyTimer expiryTimestamp={introTimer} running={introTimerStop} autostart={false} record={record} /> : null}

                                        </div>
                                    </div>
                                </Paper>
                                {
                                    PaperListArr?.queOptArr?.length > 0 && PaperListArr?.queOptArr.map((data, index) => (
                                        < Paper elevation={3} className={classes.quepos} key={index}>
                                            <div className={classes.pos}>
                                                <h2>{data.que}</h2>
                                            </div>
                                            <div style={{ margin: 15 }}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup value={examOptions[index] ? examOptions[index] : ''}
                                                        onChange={(e) => handleOptnChng(e.target.value, index)}
                                                    >
                                                        {
                                                            data.options.length > 0 && data.options.map((optionData, idx) => (
                                                                <FormControlLabel key={idx} value={optionData.option_text} control={<Radio />} label={optionData.option_text} />
                                                            ))
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </Paper>
                                    ))

                                }
                                {PaperListArr?.queOptArr?.length > 0 && <div style={{ textAlign: 'center' }}>
                                    <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                                        <Button variant="contained" color="primary" onClick={() => handleSubmitExam()}
                                        >Submit Exam</Button>
                                    </ButtonGroup>
                                </div>}
                            </Grid>
                        </Grid>
                    </Container>
                </main>}
        </div>
    )
}
