import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import { LaptopChromebook } from '@material-ui/icons';

export default function mainListItems(props) {
    const { sidebar, setSidebar } = props
    return (
        <div>
            <ListItem className="onhover" button style={{ backgroundColor: sidebar !== "Dashboard" ? "white" : "grey" }} onClick={() => setSidebar("Dashboard")}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem className="onhover" button style={{ backgroundColor: sidebar !== "Candidates" ? "white" : "grey" }} onClick={() => setSidebar("Candidates")}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Candidates" />
            </ListItem>
            <ListItem className="onhover" button style={{ backgroundColor: sidebar !== "Technology" ? "white" : "grey" }} onClick={() => setSidebar("Technology")}>
                <ListItemIcon>
                    <LaptopChromebook />
                </ListItemIcon>
                <ListItemText primary="Technology" />
            </ListItem>
            <ListItem className="onhover" button style={{ backgroundColor: sidebar !== "Create Paper" ? "white" : "grey" }} onClick={() => setSidebar("Create Paper")}>
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Create Paper" />
            </ListItem>
            <ListItem className="onhover" button style={{ backgroundColor: sidebar !== "Papers List" ? "white" : "grey" }} onClick={() => setSidebar("Papers List")}>
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Papers List" />
            </ListItem>
            <ListItem className="onhover" button style={{ backgroundColor: sidebar !== "Criterias" ? "white" : "grey" }} onClick={() => setSidebar("Criterias")}>
                <ListItemIcon>
                    <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Criterias" />
            </ListItem>
        </div>
    )
}

