import React from 'react'
import Title from '../AdminPanal/title';

export default function ExamOver() {
   
    return (
        <div>
            <Title >Exam is over, HR will get back to you. Also you can check your score in your profile</Title>
        </div>
    )
}
