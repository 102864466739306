import React, { Component } from 'react'
import { connect } from 'react-redux'
import Navigation from './Navigation'
import { CircularProgress } from '@material-ui/core';

class Container extends Component {

    render() {
        const { isLoading } = this.props
        return (
            <div>
                <Navigation />
                {
                    //Common loader
                    isLoading ?
                        <div className="newloader-style">
                            <CircularProgress size="4rem" />
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.global.loading
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
