import React, { useState, useEffect } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import { GetAudio } from '../../Store/Admin/index';
import 'react-h5-audio-player/lib/styles.css';

export default function Audio(data) {
    const [src, setsrc] = useState('')
    const dispatch = useDispatch();
    const audioData = useSelector(state => state.admin.audio)

    useEffect(() => {
        if (Object.keys(audioData).length > 0 && audioData.data.status === undefined) {
            setsrc(audioData.config.url)
        }
    }, [audioData])

    useEffect(() => {
        dispatch(GetAudio(data.id, {
            SuccessCallback: res => {
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }, [dispatch, data.id])

    return (
        <div>
            {
                src !== ''
                    ? <AudioPlayer
                        style={{ width: '300px' }}
                        src={'http://ims.sigmasolve.net:5000/api/audio/' + data.id}
                        onPlay={e => console.log("onPlay")}
                        customAdditionalControls={[]}
                        customVolumeControls={[]}
                        showJumpControls={false}
                        autoPlay={false}
                        layout="horizontal-reverse"
                    />
                    : null
            }
        </div>
    )
}
