import React, { useState, useEffect } from 'react'
import { useTimer } from 'react-timer-hook';
import { Mic, MicOff } from '@material-ui/icons';

export default function MyTimer({ expiryTimestamp, running, autostart, record }) {
    const [mic, setMic] = useState(false)

    const {
        seconds,
        minutes,
        isRunning,
        start
    } = useTimer({ expiryTimestamp, autoStart: autostart, onExpire: () => console.warn('onExpire called') });

    useEffect(() => {
        if (record === null && !isRunning) {
            running()
        } else if (record !== null && record === false && isRunning && !mic) {
            running(isRunning)
            setMic(true)
        } else if (record !== null && record === true && !isRunning) {
            running(isRunning)
        }
    }, [isRunning, record, mic])

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '80px' }}>
                <span>{minutes < 10 ? '0' + minutes : minutes}</span>:<span>{seconds < 10 ? '0' + seconds : seconds}</span>
                {
                    !autostart &&
                    <>
                        <p>Press the microphone to record</p>
                        {
                            !mic
                                ? <Mic className="onhover" onClick={start}
                                    style={{ width: 80, height: 80, margin: 30, color: "#5488fb" }}
                                />
                                : <MicOff
                                    style={{ width: 80, height: 80, margin: 30, color: "#6e7177" }}
                                />
                        }
                    </>
                }
            </div>
        </div>
    );
}
