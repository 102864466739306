import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppBar, Toolbar, Typography, Container, Grid, Paper, Button, IconButton, InputLabel, FormHelperText, Select, MenuItem, FormControl, TextField } from '@material-ui/core';
import { validatePhoneNo } from "../../Utils/constant";
import { useDispatch, useSelector } from 'react-redux';
import { toastAlertType, toastAlert } from '../../CommonComponents/Toast/Toast';
import { GetTechnology, UpdateProfile, ApplyForExam } from '../../Store/Profile/index';
import { HandleLogout } from '../../Store/Login/index';
import localVariable from '../../Utils/LocalVariables.json';
import { PowerSettingsNew } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        flexGrow: 1
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        textAlign: '-webkit-center'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: '80vh',
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(6),
        right: theme.spacing(35),
    },
}));

export default function Profile() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    let history = useHistory();
    const [userId, setUserId] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [tech, setTech] = useState("")
    const [score, setScore] = useState("")
    const [submit, setSubmit] = useState(false);
    const [disableValue, setDisableValue] = useState(true);

    const dispatch = useDispatch();
    const registerData = useSelector(state => state.login.loginData)
    const technologyArr = useSelector(state => state.profile.technology)

    useEffect(() => {
        let user = ""
        if (Object.keys(registerData).length > 0) {
            user = registerData.user
        } else {
            user = JSON.parse(localStorage.getItem(localVariable.LOGINCODE))
        }
        if (user.roleId === 1) history.push("/admin");
        setUserId(user.id)
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        setPhone(user.phoneNumber)
        setTech((user.techId !== null && user.techId !== undefined) ? user.techId : "")
        setScore((user.score !== null && user.score !== undefined) ? user.score : "")
    }, [registerData, history])

    useEffect(() => {
        const getTechnologies = () => {
            dispatch(GetTechnology(null, {
                SuccessCallback: res => {
                    if (res.data.status === true) {
                    } else {
                        toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                    }
                },
                FailureCallback: error => alert(JSON.stringify(error))
            }))
        }
        getTechnologies()
    }, [dispatch])


    const handlePhoneNumberChange = (e) => {
        let phone = e.target.value
        if (phone !== "" && isNaN(parseInt(phone))) {
            return
        } else {
            phone !== "" ? setPhone(parseInt(phone)) : setPhone(phone)
        }
    }

    const handleEdit = () => {
        setDisableValue(false)
    }

    const handleSave = () => {
        setSubmit(true)
        if (firstName !== "" && lastName !== "" &&
            phone !== "" && validatePhoneNo(phone) && tech !== "") {
            setDisableValue(true)
            const params = {
                "userId": userId,
                "firstName": firstName,
                "lastName": lastName,
                "phoneNumber": phone,
                "techId": tech
            }
            let user = JSON.parse(localStorage.getItem(localVariable.LOGINCODE))
            user.firstName = firstName
            user.lastName = lastName
            user.phoneNumber = phone
            user.techId = tech
            localStorage.setItem(localVariable.LOGINCODE, JSON.stringify(user))

            dispatch(UpdateProfile(params, {
                SuccessCallback: res => {
                    if (res.data.status === true) {
                        toastAlert(res.data.data.result.message, toastAlertType.SUCCESS)
                    } else {
                        toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                    }
                },
                FailureCallback: error => alert(JSON.stringify(error))
            }))

        }
    }

    const handleApplyForExam = () => {
        if (firstName !== "" && lastName !== "" && phone !== "" && validatePhoneNo(phone) && tech !== "") {
            let params = {
                "userId": userId
            }
            dispatch(ApplyForExam(params, {
                SuccessCallback: res => {
                    if (res.data.status === true) {
                        toastAlert(res.data.data.result.message, toastAlertType.SUCCESS)
                    } else {
                        toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                    }
                },
                FailureCallback: error => alert(JSON.stringify(error))
            }))
        } else {
            toastAlert("Please update your profile", toastAlertType.ERROR)
        }
    }

    const logout = () => {
        dispatch((HandleLogout()))
        history.push("/");
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute">
                <Toolbar className={classes.toolbar}>
                    {/* <img src={sigmaLogo} alt="logo" className={classes.logo} /> */}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        My Profile
                    </Typography>
                    <IconButton color="inherit" onClick={logout}>
                        <PowerSettingsNew />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper className={fixedHeightPaper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField autoComplete="fname" name="firstName" variant="outlined" required
                                        fullWidth id="firstName" label="First Name" disabled={disableValue}
                                        error={firstName === "" && submit} helperText={firstName === "" && submit ? "Please enter FirstName" : ""}
                                        value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField variant="outlined" required fullWidth id="lastName" label="Last Name"
                                        name="lastName" autoComplete="lname" disabled={disableValue}
                                        error={lastName === "" && submit} helperText={(lastName === "" && submit) ? "Please enter LastName" : ""}
                                        value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField variant="outlined" required fullWidth id="phonenumber" label="Phone Number"
                                        name="phonenumber" autoComplete="phone number" disabled
                                        error={((phone === "" && submit) || (submit && !validatePhoneNo(phone)))}
                                        helperText={((phone === "" && submit) || (submit && !validatePhoneNo(phone))) ? "Please enter valid Phone Number" : ""}
                                        value={phone} onChange={handlePhoneNumberChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField variant="outlined" required fullWidth id="email" label="Email Address"
                                        name="email" autoComplete="email" disabled
                                        value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl required variant="outlined" fullWidth disabled={disableValue} error={(tech === "" && submit)}>
                                        <InputLabel id="demo-simple-select-outlined-label">Technologies</InputLabel>
                                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" required
                                            value={tech} onChange={(e) => setTech(e.target.value)} label="Technologies">
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            {
                                                technologyArr.length > 0 && technologyArr.map((data) => (
                                                    < MenuItem value={data.id} key={data.id}>{data.techName}</MenuItem>)
                                                )
                                            }
                                        </Select>
                                        {(tech === "" && submit) ? <FormHelperText>Please select technology</FormHelperText> : null}
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <TextField variant="outlined" fullWidth id="score" label="Score"
                                        name="score" autoComplete="score" disabled
                                        value={score} onChange={(e) => setScore(e.target.value)} />
                                </Grid>
                                <div className={classes.fab}>
                                    <div style={{ display: 'flex' }}>
                                        <Grid style={{ margin: '10px' }}>
                                            <Button type="button" fullWidth variant="contained" color="primary"
                                                onClick={disableValue ? handleEdit : handleSave}>
                                                {disableValue ? "Edit" : "Save"}
                                            </Button>
                                        </Grid>
                                        <Grid style={{ margin: '10px' }}>
                                            <Button type="button" fullWidth variant="contained" color="primary" onClick={handleApplyForExam}>
                                                Apply for Exam
                                             </Button>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Paper>
                    </Grid>
                </Container>
            </main>
        </div >
    );
}