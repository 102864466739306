import * as types from './actionTypes'

// Initial states
const initialState = {
    loginData: {}
};

export default function apiCallDashboard(state = initialState, action = {}) {
    switch (action.type) {
        case types.LOGOUT_FETCH_SUCCESS: {
            return initialState
        }
        case types.LOGIN_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.LOGIN_FETCH_SUCCESS:
            return {
                ...state,
                loginData: action.payload,
                fetching: false,
            }
        case types.LOGIN_FETCH_FAIL:
            return {
                ...state,
                loginData: action.payload,
                fetching: false,
            }
        case types.REGISTER_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.REGISTER_FETCH_SUCCESS:
            return {
                ...state,
                loginData: action.payload,
                fetching: false,
            }
        case types.REGISTER_FETCH_FAIL:
            return {
                ...state,
                loginData: action.payload,
                fetching: false,
            }

        default:
            return state;
    }
}