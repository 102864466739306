import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Title from './title';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import { LaptopChromebook } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'center',
        '& > *': {
            margin: 50,
            width: 250,
            height: 200,
        },
    },
}));

export default function Dashboard(props) {
    const classes = useStyles();
    const { setSidebar } = props
    return (
        <div className={classes.root}>
            <Paper className="onhover" elevation={3} onClick={() => setSidebar("Candidates")}>
                <PeopleIcon style={{ width: 80, height: 80, margin: 30 }} />
                <Title >Candidates</Title>
            </Paper>
            <Paper className="onhover" elevation={3} onClick={() => setSidebar("Technology")}>
                <LaptopChromebook style={{ width: 80, height: 80, margin: 30 }} />
                <Title >Technology</Title>
            </Paper>
            <Paper className="onhover" elevation={3} onClick={() => setSidebar("Create Paper")}>
                <BarChartIcon style={{ width: 80, height: 80, margin: 30 }} />
                <Title >Create Paper</Title>
            </Paper>
            <Paper className="onhover" elevation={3} onClick={() => setSidebar("Papers List")}>
                <AssignmentIcon style={{ width: 80, height: 80, margin: 30 }} />
                <Title >Paper List</Title>
            </Paper>
            <Paper className="onhover" elevation={3} onClick={() => setSidebar("Criterias")}>
                <LayersIcon style={{ width: 80, height: 80, margin: 30 }} />
                <Title >Criterias</Title>
            </Paper>
        </div>
    );
}
