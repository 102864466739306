import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from "react-router-dom";
import { Container, Typography, Avatar, Button, CssBaseline, TextField, Grid, } from '@material-ui/core';
import { validateEmail } from "../../Utils/constant";
import { HandleLogin } from '../../Store/Login/index';
import { toastAlertType, toastAlert } from '../../CommonComponents/Toast/Toast';
import localVariable from '../../Utils/LocalVariables.json';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
}));

export default function Login() {
    const classes = useStyles();
    let history = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [submit, setSubmit] = useState(false);

    const dispatch = useDispatch();
    const loginData = useSelector(state => state.login.loginData)

    useEffect(() => {
        if (Object.keys(loginData).length > 0 && loginData.user) {
            const loginUserObj = loginData.user
            localStorage.setItem(localVariable.LOGINCODE, JSON.stringify(loginUserObj))
            history.push(loginData.user.roleId === 1 ? "/admin" : "/profile");
        }
    }, [loginData, history])

    const handleSubmitClick = () => {
        setSubmit(true)
        if (email !== "" && validateEmail(email) && password !== "") {
            let params = {
                "username": email,
                "password": password
            }
            dispatch(HandleLogin(params, {
                SuccessCallback: res => {
                    if (res.data.status === true) {
                    } else {
                        toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                    }
                },
                FailureCallback: error => alert(JSON.stringify(error))
            }))
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}><LockOutlinedIcon /></Avatar>
                <Typography component="h1" variant="h5">Sign in</Typography>

                <form className={classes.form} noValidate>
                    <TextField variant="outlined" margin="normal" required fullWidth id="email"
                        label="Email Address" name="email" autoComplete="email" autoFocus
                        error={((email === "" && submit) || (submit && !validateEmail(email)))}
                        helperText={((email === "" && submit) || (submit && !validateEmail(email))) ? "Please enter valid Email" : ""}
                        value={email} onChange={(e) => setEmail(e.target.value)} />
                    <TextField variant="outlined" margin="normal" required fullWidth name="password"
                        label="Password" type="password" id="password" autoComplete="current-password"
                        error={password === "" && submit} helperText={(password === "" && submit) ? "Please enter Password" : ""}
                        value={password} onChange={(e) => setPassword(e.target.value)} />
                    <Button type="button" fullWidth variant="contained"
                        color="primary" className={classes.submit} onClick={() => handleSubmitClick()}>
                        Sign In
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to={{ pathname: "/register" }}>{"Don't have an account? Sign Up"}</Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}