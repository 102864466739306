//Login
export const LOGIN_FETCH_REQUEST = 'LOGIN_FETCH_REQUEST';
export const LOGIN_FETCH_SUCCESS = 'LOGIN_FETCH_SUCCESS';
export const LOGIN_FETCH_FAIL = 'LOGIN_FETCH_FAIL';

//Login
export const REGISTER_FETCH_REQUEST = 'REGISTER_FETCH_REQUEST';
export const REGISTER_FETCH_SUCCESS = 'REGISTER_FETCH_SUCCESS';
export const REGISTER_FETCH_FAIL = 'REGISTER_FETCH_FAIL';

//Logout
export const LOGOUT_FETCH_SUCCESS = 'LOGOUT_FETCH_SUCCESS';
