import React, { useState, useEffect } from 'react'
import { Typography, Grid, Paper, Button, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toastAlertType, toastAlert } from '../../CommonComponents/Toast/Toast';
import { GetCriteria, UpdateCriteria } from '../../Store/Admin/index';

export default function Criteria() {
    const [examTime, setexamTime] = useState('')
    const [introTime, setintroTime] = useState('')
    const [idleTime, setidleTime] = useState('')
    const [passingMarks, setpassingMarks] = useState('')
    const [submit, setsubmit] = useState(false)

    const dispatch = useDispatch();
    const Criteria = useSelector(state => state.admin.criteriaList)

    useEffect(() => {
        if (Criteria.examTime) {
            setexamTime(Criteria.examTime)
            setintroTime(Criteria.introTime)
            setidleTime(Criteria.idleTime)
            setpassingMarks(Criteria.passingMarks)
        }
    }, [Criteria])

    useEffect(() => {
        dispatch(GetCriteria(null, {
            SuccessCallback: res => {
                if (res.data.status === true) {
                } else {
                    toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                }
            },
            FailureCallback: error => alert(JSON.stringify(error))
        }))
    }, [dispatch])

    const handleUpdateClick = () => {
        setsubmit(true)
        if (examTime !== "" && introTime !== "" && idleTime !== "" && passingMarks !== "") {
            let params = {
                "id": 1,
                "examTime": examTime,
                "introTime": introTime,
                "passingMarks": passingMarks,
                "idleTime": idleTime
            }
            dispatch(UpdateCriteria(params, {
                SuccessCallback: res => {
                    if (res.data.status === true) {
                        toastAlert(res.data.data.result.message, toastAlertType.SUCCESS)
                    } else {
                        toastAlert(res.data.data.result.message, toastAlertType.ERROR)
                    }
                },
                FailureCallback: error => alert(JSON.stringify(error))
            }))
        } else {
            toastAlert("Criteria cannot be empty", toastAlertType.ERROR)
        }
    }

    const handleChange = (e, str) => {
        let change = e.target.value
        if (change !== "" && isNaN(parseInt(change))) {
            return
        } else {
            if (str === 'exam') { change !== "" ? setexamTime(parseInt(change)) : setexamTime(change) }
            if (str === 'intro') { change !== "" ? setintroTime(parseInt(change)) : setintroTime(change) }
            if (str === 'idle') { change !== "" ? setidleTime(parseInt(change)) : setidleTime(change) }
            if (str === 'marks') { change !== "" ? setpassingMarks(parseInt(change)) : setpassingMarks(change) }
        }
    }
    return (
        <div>
            <Paper>
                <Grid container spacing={3}>
                    <Grid style={{ margin: '10px' }} item >
                        <Typography component="h1" variant="h6" color="inherit" noWrap >
                            Exam Time
                        </Typography>
                        <TextField autoComplete="Exam" name="examtime" variant="outlined" required
                            fullWidth id="examtime" label="Exam Time" error={examTime === "" && submit} helperText={examTime === "" && submit ? "Please enter examTime" : ""}
                            value={examTime} onChange={(e) => handleChange(e, "exam")} />
                    </Grid>
                    <Grid style={{ margin: '10px' }} item >
                        <Typography component="h1" variant="h6" color="inherit" noWrap >
                            Introduction Time
                        </Typography>
                        <TextField autoComplete="iTime" name="introtime" variant="outlined" required
                            fullWidth id="introtime" label="Intro Time" error={introTime === "" && submit} helperText={introTime === "" && submit ? "Please enter introTime" : ""}
                            value={introTime} onChange={(e) => handleChange(e, "intro")} />
                    </Grid>
                    <Grid style={{ margin: '10px' }} item >
                        <Typography component="h1" variant="h6" color="inherit" noWrap >
                            Idle Timeout
                        </Typography>
                        <TextField autoComplete="idletime" name="idletime" variant="outlined" required
                            fullWidth id="idletime" label="Idle Time" error={idleTime === "" && submit} helperText={idleTime === "" && submit ? "Please enter idleTime" : ""}
                            value={idleTime} onChange={(e) => handleChange(e, "idle")} />
                    </Grid>
                    <Grid style={{ margin: '10px' }} item >
                        <Typography component="h1" variant="h6" color="inherit" noWrap >
                            Passing Marks
                        </Typography>
                        <TextField autoComplete="pmarks" name="passingmarks" variant="outlined" required
                            fullWidth id="passingmarks" label="Passing Marks" error={passingMarks === "" && submit} helperText={passingMarks === "" && submit ? "Please enter passingMarks" : ""}
                            value={passingMarks} onChange={(e) => handleChange(e, "marks")} />
                    </Grid>
                </Grid>
                <div style={{ textAlign: 'center', margin: '50px', padding: 40 }}>
                    <Button type="button" style={{ width: '250px' }} fullWidth variant="contained" color="primary" onClick={handleUpdateClick}>
                        Update Criteria
                    </Button>
                </div>
            </Paper>
        </div>
    )
}
