import * as types from './actionTypes'

// Initial states
const initialState = {
    userList: [],
    technology: {},
    createPaper: {},
    getPaperList: {},
    getPaperSetList: [],
    userListUpdateMsg: {},
    emailMsg: {},
    examResult: {},
    criteriaList: {},
    updateCriteria: {},
    getStartTime: {},
    updateStartTime: {},
    audio: {}
};

export default function apiCallDashboard(state = initialState, action = {}) {
    switch (action.type) {
        case types.USER_LIST_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.USER_LIST_FETCH_SUCCESS:
            return {
                ...state,
                userList: action.payload,
                fetching: false,
            }
        case types.USER_LIST_FETCH_FAIL:
            return {
                ...state,
                userList: action.payload,
                fetching: false,
            }
        case types.ADD_TECH_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.ADD_TECH_FETCH_SUCCESS:
            return {
                ...state,
                technology: action.payload,
                fetching: false,
            }
        case types.ADD_TECH_FETCH_FAIL:
            return {
                ...state,
                technology: action.payload,
                fetching: false,
            }
        case types.DELETE_TECH_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.DELETE_TECH_FETCH_SUCCESS:
            return {
                ...state,
                technology: action.payload,
                fetching: false,
            }
        case types.DELETE_TECH_FETCH_FAIL:
            return {
                ...state,
                technology: action.payload,
                fetching: false,
            }
        case types.CREATE_PAPER_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.CREATE_PAPER_FETCH_SUCCESS:
            return {
                ...state,
                createPaper: action.payload,
                fetching: false,
            }
        case types.CREATE_PAPER_FETCH_FAIL:
            return {
                ...state,
                createPaper: action.payload,
                fetching: false,
            }
        case types.GET_PAPER_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.GET_PAPER_FETCH_SUCCESS:
            return {
                ...state,
                getPaperList: action.payload,
                fetching: false,
            }
        case types.GET_PAPER_FETCH_FAIL:
            return {
                ...state,
                getPaperList: action.payload,
                fetching: false,
            }
        case types.GET_PAPERSET_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.GET_PAPERSET_FETCH_SUCCESS:
            return {
                ...state,
                getPaperSetList: action.payload,
                fetching: false,
            }
        case types.GET_PAPERSET_FETCH_FAIL:
            return {
                ...state,
                getPaperSetList: action.payload,
                fetching: false,
            }
        case types.UPDATE_USER_LIST_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.UPDATE_USER_LIST_FETCH_SUCCESS:
            return {
                ...state,
                userListUpdateMsg: action.payload,
                fetching: false,
            }
        case types.UPDATE_USER_LIST_FETCH_FAIL:
            return {
                ...state,
                userListUpdateMsg: action.payload,
                fetching: false,
            }
        case types.EMAIL_LINK_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.EMAIL_LINK_FETCH_SUCCESS:
            return {
                ...state,
                emailMsg: action.payload,
                fetching: false,
            }
        case types.EMAIL_LINK_FETCH_FAIL:
            return {
                ...state,
                emailMsg: action.payload,
                fetching: false,
            }
        case types.EXAM_RESULT_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.EXAM_RESULT_FETCH_SUCCESS:
            return {
                ...state,
                examResult: action.payload,
                fetching: false,
            }
        case types.EXAM_RESULT_FETCH_FAIL:
            return {
                ...state,
                examResult: action.payload,
                fetching: false,
            }
        case types.GET_CRITERIA_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.GET_CRITERIA_FETCH_SUCCESS:
            return {
                ...state,
                criteriaList: action.payload[0],
                fetching: false,
            }
        case types.GET_CRITERIA_FETCH_FAIL:
            return {
                ...state,
                criteriaList: action.payload,
                fetching: false,
            }
        case types.UPDATE_CRITERIA_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.UPDATE_CRITERIA_FETCH_SUCCESS:
            return {
                ...state,
                updateCriteria: action.payload,
                fetching: false,
            }
        case types.UPDATE_CRITERIA_FETCH_FAIL:
            return {
                ...state,
                updateCriteria: action.payload,
                fetching: false,
            }
        case types.GET_START_EXAM_TIME_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.GET_START_EXAM_TIME_FETCH_SUCCESS:
            return {
                ...state,
                getStartTime: action.payload,
                fetching: false,
            }
        case types.GET_START_EXAM_TIME_FETCH_FAIL:
            return {
                ...state,
                getStartTime: action.payload,
                fetching: false,
            }
        case types.UPDATE_START_EXAM_TIME_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.UPDATE_START_EXAM_TIME_FETCH_SUCCESS:
            return {
                ...state,
                updateStartTime: action.payload,
                fetching: false,
            }
        case types.UPDATE_START_EXAM_TIME_FETCH_FAIL:
            return {
                ...state,
                updateStartTime: action.payload,
                fetching: false,
            }
        case types.AUDIO_FETCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case types.AUDIO_FETCH_SUCCESS:
            return {
                ...state,
                audio: action.payload,
                fetching: false,
            }
        case types.AUDIO_FETCH_FAIL:
            return {
                ...state,
                audio: action.payload,
                fetching: false,
            }
        default:
            return state;
    }
}