import * as types from './actionTypes';
import { endPoints } from '../../CommonApiController/AppConfig';
import { RestApiController } from '../../CommonApiController/ApiController';
import { apiLoadingStart, apiLoadingStop } from '../Global/actions';


export const GetUserList = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.USER_LIST_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.UserList.endpoint}`, params, endPoints.UserList.header, endPoints.UserList.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.USER_LIST_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.USER_LIST_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const AddTechnology = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.ADD_TECH_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.AddTech.endpoint}`, params, endPoints.AddTech.header, endPoints.AddTech.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.ADD_TECH_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.ADD_TECH_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const DeleteTechnology = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.DELETE_TECH_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.DeleteTech.endpoint}`, params, endPoints.DeleteTech.header, endPoints.DeleteTech.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.DELETE_TECH_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.DELETE_TECH_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const CreatePaper = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.CREATE_PAPER_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.CreatePaper.endpoint}`, params, endPoints.CreatePaper.header, endPoints.CreatePaper.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.CREATE_PAPER_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.CREATE_PAPER_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};
export const GetPaper = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.GET_PAPER_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.GetPaper.endpoint}`, params, endPoints.GetPaper.header, endPoints.GetPaper.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.GET_PAPER_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.GET_PAPER_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const GetPaperSet = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.GET_PAPERSET_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.GetPaperSet.endpoint}`, params, endPoints.GetPaperSet.header, endPoints.GetPaperSet.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.GET_PAPERSET_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.GET_PAPERSET_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const UpdateUserList = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.UPDATE_USER_LIST_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.UpdateUserList.endpoint}`, params, endPoints.UpdateUserList.header, endPoints.UpdateUserList.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.UPDATE_USER_LIST_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.UPDATE_USER_LIST_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const EmailLink = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.EMAIL_LINK_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.EmailLink.endpoint}`, params, endPoints.EmailLink.header, endPoints.EmailLink.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.EMAIL_LINK_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.EMAIL_LINK_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const ExamResult = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.EXAM_RESULT_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.ExamResult.endpoint}`, params, endPoints.ExamResult.header, endPoints.ExamResult.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.EXAM_RESULT_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.EXAM_RESULT_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};
export const GetCriteria = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.GET_CRITERIA_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.GetCriteria.endpoint}`, params, endPoints.GetCriteria.header, endPoints.GetCriteria.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.GET_CRITERIA_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.GET_CRITERIA_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};
export const UpdateCriteria = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.UPDATE_CRITERIA_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.UpdateCriteria.endpoint}`, params, endPoints.UpdateCriteria.header, endPoints.UpdateCriteria.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.UPDATE_CRITERIA_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.UPDATE_CRITERIA_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};
export const GetStartTime = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.GET_START_EXAM_TIME_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.GetStartTime.endpoint}`, params, endPoints.GetStartTime.header, endPoints.GetStartTime.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.GET_START_EXAM_TIME_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.GET_START_EXAM_TIME_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};
export const UpdateStartTime = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.UPDATE_START_EXAM_TIME_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.UpdateStartTime.endpoint}`, params, endPoints.UpdateStartTime.header, endPoints.UpdateStartTime.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.UPDATE_START_EXAM_TIME_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.UPDATE_START_EXAM_TIME_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const GetAudio = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.AUDIO_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.GetAudio.endpoint}/${params}`,null, endPoints.GetAudio.header, endPoints.GetAudio.method,
            {
                SuccessCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.AUDIO_FETCH_SUCCESS, payload: response })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.AUDIO_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};