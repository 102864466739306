import React from "react";
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import localVariable from './Utils/LocalVariables.json';
import Login from "./Screens/Login/login";
import Register from "./Screens/Register/register";
import Admin from "./Screens/AdminPanal/admin";
import Profile from "./Screens/Profile/profile";
import Exam from "./Screens/Exam/examPaper";
import CryptoJS from "crypto-js";
import ExamOver from "./Screens/Exam/ExamOver";

/**
 * Display if route is not exists
*/
function NoMatch() {
  let location = useLocation();

  return (
    <div className="content-wrapper">
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

/**
 * Common private route
*/
function PrivateRoute({ children, ...rest }) {
  const user = localStorage.getItem(localVariable.LOGINCODE)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


/**
 * Login private route
*/
function LoginRoute({ children, ...rest }) {
  const user = localStorage.getItem(localVariable.LOGINCODE)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          <Redirect
            to={{
              pathname: (JSON.parse(user)).roleId === 1 ? "/admin" : "/profile",
              state: { from: location }
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

function ExamRoute({ children, ...rest }) {
  let location = useLocation();
  let refirectFlag = false;
  let bytes = (CryptoJS.AES.decrypt(location.pathname.substring(6), "sigma-solve-secret-key")).toString(CryptoJS.enc.Utf8);
  if (bytes !== "") {
    let decryptedData = JSON.parse(bytes);
    refirectFlag = (((new Date() / 1000) - decryptedData.date) < 43200)
    if (refirectFlag) localStorage.setItem(localVariable.USERDATA, JSON.stringify(decryptedData))
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        refirectFlag ? (
          children
        ) : (
          <NoMatch />
        )
      }
    />
  );
}

/**
 * Define application routes
*/
export default function Navigation(props) {
  return (
    <Router>
      <div>
        <Switch>
          <LoginRoute path="/" exact >
            <Login />
          </LoginRoute>

          <LoginRoute exact path="/register">
            <Register />
          </LoginRoute>

          <PrivateRoute exact path="/admin">
            <Admin />
          </PrivateRoute>

          <PrivateRoute exact path="/profile">
            <Profile />
          </PrivateRoute>
          
          <Route exact path="/examover">
            <ExamOver />
          </Route>

          <ExamRoute path="/exam">
            <Exam />
          </ExamRoute>

          <Route path="*">
            <NoMatch />
          </Route>

        </Switch>
      </div>
    </Router>
  )
}