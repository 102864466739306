//User List
export const USER_LIST_FETCH_REQUEST = 'USER_LIST_FETCH_REQUEST';
export const USER_LIST_FETCH_SUCCESS = 'USER_LIST_FETCH_SUCCESS';
export const USER_LIST_FETCH_FAIL = 'USER_LIST_FETCH_FAIL';

//Add Tech
export const ADD_TECH_FETCH_REQUEST = 'ADD_TECH_FETCH_REQUEST';
export const ADD_TECH_FETCH_SUCCESS = 'ADD_TECH_FETCH_SUCCESS';
export const ADD_TECH_FETCH_FAIL = 'ADD_TECH_FETCH_FAIL';

//Delete Tech
export const DELETE_TECH_FETCH_REQUEST = 'DELETE_TECH_FETCH_REQUEST';
export const DELETE_TECH_FETCH_SUCCESS = 'DELETE_TECH_FETCH_SUCCESS';
export const DELETE_TECH_FETCH_FAIL = 'DELETE_TECH_FETCH_FAIL';

//Create Paper
export const CREATE_PAPER_FETCH_REQUEST = 'CREATE_PAPER_FETCH_REQUEST';
export const CREATE_PAPER_FETCH_SUCCESS = 'CREATE_PAPER_FETCH_SUCCESS';
export const CREATE_PAPER_FETCH_FAIL = 'CREATE_PAPER_FETCH_FAIL';

//Get Paper
export const GET_PAPER_FETCH_REQUEST = 'GET_PAPER_FETCH_REQUEST';
export const GET_PAPER_FETCH_SUCCESS = 'GET_PAPER_FETCH_SUCCESS';
export const GET_PAPER_FETCH_FAIL = 'GET_PAPER_FETCH_FAIL';

//Get PaperSet
export const GET_PAPERSET_FETCH_REQUEST = 'GET_PAPERSET_FETCH_REQUEST';
export const GET_PAPERSET_FETCH_SUCCESS = 'GET_PAPERSET_FETCH_SUCCESS';
export const GET_PAPERSET_FETCH_FAIL = 'GET_PAPERSET_FETCH_FAIL';

//Get PaperSet
export const UPDATE_USER_LIST_FETCH_REQUEST = 'UPDATE_USER_LIST_FETCH_REQUEST';
export const UPDATE_USER_LIST_FETCH_SUCCESS = 'UPDATE_USER_LIST_FETCH_SUCCESS';
export const UPDATE_USER_LIST_FETCH_FAIL = 'UPDATE_USER_LIST_FETCH_FAIL';

//Email Link
export const EMAIL_LINK_FETCH_REQUEST = 'EMAIL_LINK_FETCH_REQUEST';
export const EMAIL_LINK_FETCH_SUCCESS = 'EMAIL_LINK_FETCH_SUCCESS';
export const EMAIL_LINK_FETCH_FAIL = 'EMAIL_LINK_FETCH_FAIL';

//Exam Result
export const EXAM_RESULT_FETCH_REQUEST = 'EXAM_RESULT_FETCH_REQUEST';
export const EXAM_RESULT_FETCH_SUCCESS = 'EXAM_RESULT_FETCH_SUCCESS';
export const EXAM_RESULT_FETCH_FAIL = 'EXAM_RESULT_FETCH_FAIL';

//get Criteria
export const GET_CRITERIA_FETCH_REQUEST = 'GET_CRITERIA_FETCH_REQUEST';
export const GET_CRITERIA_FETCH_SUCCESS = 'GET_CRITERIA_FETCH_SUCCESS';
export const GET_CRITERIA_FETCH_FAIL = 'GET_CRITERIA_FETCH_FAIL';

//get Criteria
export const UPDATE_CRITERIA_FETCH_REQUEST = 'UPDATE_CRITERIA_FETCH_REQUEST';
export const UPDATE_CRITERIA_FETCH_SUCCESS = 'UPDATE_CRITERIA_FETCH_SUCCESS';
export const UPDATE_CRITERIA_FETCH_FAIL = 'UPDATE_CRITERIA_FETCH_FAIL';

//get Criteria
export const GET_START_EXAM_TIME_FETCH_REQUEST = 'GET_START_EXAM_TIME_FETCH_REQUEST';
export const GET_START_EXAM_TIME_FETCH_SUCCESS = 'GET_START_EXAM_TIME_FETCH_SUCCESS';
export const GET_START_EXAM_TIME_FETCH_FAIL = 'GET_START_EXAM_TIME_FETCH_FAIL';

//get Criteria
export const UPDATE_START_EXAM_TIME_FETCH_REQUEST = 'UPDATE_START_EXAM_TIME_FETCH_REQUEST';
export const UPDATE_START_EXAM_TIME_FETCH_SUCCESS = 'UPDATE_START_EXAM_TIME_FETCH_SUCCESS';
export const UPDATE_START_EXAM_TIME_FETCH_FAIL = 'UPDATE_START_EXAM_TIME_FETCH_FAIL';

//get Audio
export const AUDIO_FETCH_REQUEST = 'AUDIO_FETCH_REQUEST';
export const AUDIO_FETCH_SUCCESS = 'AUDIO_FETCH_SUCCESS';
export const AUDIO_FETCH_FAIL = 'AUDIO_FETCH_FAIL';