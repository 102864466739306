import * as types from './actionTypes';
import { endPoints } from '../../CommonApiController/AppConfig';
import { RestApiController } from '../../CommonApiController/ApiController';
import { apiLoadingStart, apiLoadingStop } from '../Global/actions';


export const GetTechnology = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.TECHNOLOGY_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.Technology.endpoint}`, params, endPoints.Technology.header, endPoints.Technology.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.TECHNOLOGY_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.TECHNOLOGY_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const UpdateProfile = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.UPDATE_PROFILE_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.UpdateProfile.endpoint}`, params, endPoints.UpdateProfile.header, endPoints.UpdateProfile.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.UPDATE_PROFILE_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.UPDATE_PROFILE_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};

export const ApplyForExam = (params, { SuccessCallback, FailureCallback }) => {
    return (dispatch) => {
        dispatch(apiLoadingStart());
        dispatch({ type: types.APPLY_FOR_EXAM_FETCH_REQUEST })
        // Common API call
        RestApiController(`${endPoints.ApplyForExam.endpoint}`, params, endPoints.ApplyForExam.header, endPoints.ApplyForExam.method,
            {
                SuccessCallback: response => {
                    const { data } = response.data
                    const { result } = data;
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.APPLY_FOR_EXAM_FETCH_SUCCESS, payload: result })
                    SuccessCallback(response);
                },
                FailureCallback: response => {
                    dispatch(apiLoadingStop())
                    dispatch({ type: types.APPLY_FOR_EXAM_FETCH_FAIL, payload: response })
                    FailureCallback(response);
                }
            })
    }
};
