import React from 'react'
import { Paper, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../AdminPanal/title';

const useStyles = makeStyles({
    pos: {
        marginBottom: 12,
        padding: 15,
        display: 'flex',
        position: 'relative'
    },
    quepos: {
        marginBottom: 12,
        padding: 15
    },
    optpos: {
        marginBottom: 5,
        padding: 5
    },
    formcontrol: {
        minWidth: 230
    },
    actionbtn: {
        right: 20,
        top: 20,
        position: 'absolute'
    },
    media: {
        height: 240,
    },
});
export default function ViewPaper(props) {
    const { paperArray, handleTech, view } = props
    const classes = useStyles();

    const radioValue = (id, optionArr) => {
        return optionArr.find(o => o.id === id).option_text
    }
    return (
        <div>
            <div className={classes.pos}>
                <Title>{"Technology: "}</Title><h3>{handleTech(paperArray.tech)}</h3>
                <Title>{"PaperSet-Name: "}</Title><h3>{paperArray.setName}</h3>
            </div>
            {
                paperArray.queOptArr.length > 0 && paperArray.queOptArr.map((data, index) => (
                    < Paper elevation={3} className={classes.quepos} key={index}>
                        <div className={classes.pos}>
                            <h2>{data.que}</h2>
                        </div>
                        <div style={{ margin: 15 }}>
                            <FormControl component="fieldset" disabled={view}>
                                <RadioGroup value={radioValue(data.answer.optionId, data.options)}
                                // onChange={(e) => handleChange(e, index)}
                                >
                                    {
                                        data.options.length > 0 && data.options.map((optionData, idx) => (
                                            <FormControlLabel key={idx} value={optionData.option_text} control={<Radio />} label={optionData.option_text} />
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Paper>
                ))

            }
        </div >
    )
}
